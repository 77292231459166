
/* html {
    height: 100%;
}
body {
   
   
    box-sizing: border-box;
   
   
    
    overflow: hidden;
  
    --web-view-name: Home Page;
    --web-view-id: Home_Page;
    --web-scale-to-fit: true;
    --web-scale-on-resize: true;
    --web-refresh-for-changes: true;
    --web-enable-deep-linking: true;
} */






/* body[data-aos-duration='4000'] [data-aos],
  [data-aos][data-aos][data-aos-duration='4000'] {
    transition-duration: 4000ms;
  }


body {
  font-family: Mulish, Arial, Helvetica, sans-serif;
} */


.image {
    position: absolute;
    
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
    
}
.trans1 {
    animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 0.5s;
}
@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.extra1 {
    z-index: 1000;
    animation-name: scale;
  animation-duration: 1s;
  animation-delay: 2.5s;
}
@keyframes scale {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}

.trans2 {
    animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 2.5s;
}
@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.extra2 {
    z-index: 1000;
    animation-name: scale;
  animation-duration: 1s;
  animation-delay: 4.5s;
}
@keyframes scale {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}

.trans3 {
    animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 4.5s;
}
@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.extra3 {
    z-index: 1000;
    animation-name: scale;
  animation-duration: 1s;
  animation-delay: 6.5s;
}
@keyframes scale {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}
.trans4 {
    animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 6.5s;
}
@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.extra4 {
    z-index: 1000;
    animation-name: scale;
  animation-duration: 1s;
  animation-delay: 8.5s;
}
@keyframes scale {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}
.trans5 {
    animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 8.5s;
}
@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.extra5 {
    z-index: 1000;
    animation-name: scale;
  animation-duration: 1s;
  animation-delay: 10.5s;
}
@keyframes scale {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}

.yuvaMain .z-in {
    z-index: -1000;
}



.yuvaMain .header-contain
{
    top: -1rem;
}

   

.yuvaMain .line-img-FL-Name
{
    
    margin-top: -1rem;
    width: 250px;   

}

.yuvaMain .line-img-Score
{
    
    margin-top: -1rem;
    width: 170px;

}

.yuvaMain .left-image-round {
    width: 64%;
    margin-left: 4rem;
    position: relative;
    top: 0;
    left: 0;
}


.yuvaMain .left-image-logo {
    width: 52%;
    margin-left: 5rem;
    position: absolute;
    top: 39px;
    left: 22px;
}
  



.yuvaMain .bodybackground-image{
    background:url('../images_yuva/pattern-2-video.jpg');
    -moz-background-size: cover;
    -o-background-size: cover;
    animation: mymove 5s infinite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
}

@keyframes mymove {
  100% {background-position: center;}
}

.yuvaMain .updatedData{
  padding: 8px;
  position: relative;
  right: -31rem;
  background: white;
  border-radius: 10px;
  bottom: 5px;
  font-weight: bold;
}

.yuvaMain .menu-image{
    /* background: url('2/1Asset 63@1.5x.png') ; */
    background: url('../images_yuva/1Asset 63@1.5x.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 6rem;
    background-repeat: round;
    width: 20%;
    height: auto;
}

.yuvaMain .menu-image-FlName{
  /* background: url('2/1Asset 63@1.5x.png') ; */
  background: url('../images_yuva/1Asset 63@1.5x.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 6rem;
  background-repeat: round;
  width: 35%;
  height: auto;
}

.yuvaMain .footer-div-row {
    margin-top: 2rem;
}
  
.yuvaMain .footer-img {
    width: 114%;
}

.yuvaMain .footer-text-top {
    margin-top: 4.5rem;
    margin-left: -3rem;
}

.yuvaMain .text-h1-header
{
    font-size: 3.5rem;
    font-weight: 800;
}

.yuvaMain .text-h1-vintage-menu {
    color: #ffffff;
    font-size: 3rem;
    font-weight: 800;
    margin-left: -11.6rem;
}
  
.yuvaMain .header-img
{
    height: 170px;
}

.yuvaMain .header-contain-color
{
    color: #2e4a9a;
}

.yuvaMain .left-side-row-parent {
  position: relative;
  top: 0;
  left: 0;
}


.yuvaMain .text-h1-rank-head {
    font-size: 2rem;
    font-weight: 800;

    margin-top: -5rem;
}


.yuvaMain .text-h1-flname-head {
    font-size: 2rem;
    font-weight: 800;

    margin-top: -5rem;
}


.yuvaMain .text-h1-score-head {
    font-size: 2rem;
    font-weight: 800;

    margin-top: -5rem;
}



.yuvaMain .img-number-left {
    width: 108%;


}

.yuvaMain .menu-top-contain-table {
width: 236%;


}
.yuvaMain .span-font-number {
    color: #2e4a9a;
    font-size: 2rem;
    font-weight: 800;
    
}


.yuvaMain .span-font-flname-and-score {
    color: #b32e32;
    font-size: 1.5rem;
    font-weight: 800;
    /* margin-left: 0.5rem; */
}




.yuvaMain .col-sm-1-space {
        flex: 0 0 auto;
        width: 1.333333%;
    }



    .yuvaMain .number-img-max-width
{
    max-width: 100%;
}

  
   
.yuvaMain .margin-top-menu
{
   margin-top: 3rem;
}


.yuvaMain .margin-top-menu-contain
{
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.yuvaMain .number-margin-bottom
{
    margin-bottom: 1.3rem;
}

.yuvaMain .container
{
    min-width: 1920px;
    padding: 0;
    margin: 0 auto;
}


.yuvaMain .fixed-bottom1
{
    position: fixed;
   
    bottom: 0;
  
    z-index: 1030;
   
  
}
