.loginBtn{
    background: transparent linear-gradient(90deg, #E8873C 0%, #bd4118 60%) 0% 0% no-repeat padding-box;
    width: 10rem  !important;
    /* height: 46px  !important; */
    border-radius: 5px  !important;
    text-align: center  !important;
    font-size: 20px  !important;
    font-weight: bold  !important;
    letter-spacing: 0px  !important;
    color: #FFFFFF  !important;
    border: none;
    padding: 6px;
}

.loginBtn:hover {
  color: #f9f9f9 !important;
  background-color:transparent linear-gradient(90deg, #7a2d13 60%, #E8873C 0%) 0% 0% no-repeat padding-box !important; 
}

.toastAlert{
  height: 175px !important;
  max-height: 175px !important;
  min-height: 175px !important;
}